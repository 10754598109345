.loginBtn {
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  background: #009688;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
}
.taille-img {
  /* width: 24%; */
  height: 50px;
}
.title {
  font-size: 25px;
}

.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background: #f4f4f4;
  display: flex;
}

.login .loginContainer {
  padding: 30px;
  margin: auto;
  width: 100%;
  max-width: 450px;
  min-height: 150px;
  display: flex;
  border: none;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  box-shadow: 0 0px 10px 0px rgba(10, 15, 25, 0.3);
}

.login .loginContainer label {
  margin: 14px 0;
  display: block;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
}

.login .loginContainer input {
  width: 100%;
  border: 1px solid #bab5b5;
  outline: none;
  font-size: 14px;
  padding: 5px;
  letter-spacing: 1px;
  border-radius: 5px;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 10px 0;
}

/* .login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: right;
} */

.login .loginContainer .btnContainer p span {
  letter-spacing: 0.5px;
  margin-left: 5px;
  /* cursor: pointer; */
  transition: all 400ms ease-in-out;
}

/* .login .loginContainer .btnContainer p span:hover {
  color: #009688;
} */

.login .loginContainer .errorMsg {
  color: red;
  font-size: 16px;
}
