.my-nav {
  box-shadow: 1px 0px 5px -3px rgba(10, 20, 30, 1);
  -webkit-box-shadow: 1px 0px 5px -3px rgba(10, 20, 30, 1);
  -moz-box-shadow: 1px 0px 5px -3px rgba(10, 20, 30, 1);
  background: #fff !important;
}
.navbar-toggler:active,:focus{
  border: none;
}
.nav-link{
  font-size: calc(0.4rem + 0.6vw) !important;
  padding: 0.2rem 0.4rem !important;
}
.profil-dropdown{
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: #009688;
  color: white;
  display: block;
  text-align: center;
}
.profil-dropdown:hover{
  background-color: #386a65;
  color: white;
}
a{
  transition: all 200ms ease;
}
a.active{
  color: #009688 !important;
  border-bottom: 4.5px solid #009688;
  font-weight: 600;
}