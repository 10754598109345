body{
    background-color: #f6f6f9;
}
label{
    font-weight: 300;
    font-size: calc(0.5rem + 0.9vh) !important;
    margin-bottom: 0 !important;
}
input, textarea, label, option, select{
    font-size: calc(0.5rem + 0.5vh) !important;
}
input::placeholder{
    color: rgba(0, 0, 0, 0.5) !important;
}
select,option{
    cursor: pointer;
}
ul{
    list-style: none;
}
.auth-form{
    /* height: 100vh; */
    /* display: flex; */
    justify-content: center;
    align-items: center;
}
.auth-form form{
    max-width: 500px;
    margin: auto;
}
.input-form:not(input[type="checkbox"]){
    border: none;
    padding: 10px 10px 5px 5px;
    border-bottom: 2px solid #000;
    width: 100%;
    background-color: transparent;
    transition: all ease 200ms;
    margin-bottom: 5px;
    background-color: transparent;
}
.form-is-invalid{
    border-bottom: 2px solid red;
    color: red;
}
.input-form:focus{
    border-bottom: 2px solid #FFA726;
    outline: none;
}
.bg-gradient-dark {
    background-image: linear-gradient(195deg, #42424a 0%, #191919 100%);
}
.bg-gradient-danger {
    background-image: linear-gradient(195deg, #EF5350 0%, #E53935 100%);
}
.bg-gradient-success {
    background-image: linear-gradient(195deg, #66BB6A 0%, #43A047 100%);
}
.bg-gradient-primary {
    background-image: linear-gradient(195deg, #EC407A 0%, #D81B60 100%);
}
.bg-gradient-warning {
    background-image: linear-gradient(195deg, #FFA726 0%, #FB8C00 100%);
}
.bg-gradient-info {
    background-image: linear-gradient(195deg, #49a3f1 0%, #1A73E8 100%);
}
.fs-5{
    font-size: 0.9rem !important;
}

.fs-6{
    font-size: 0.75rem !important;
}
.card-text{
    font-size: calc(0.4rem + 0.7vh);
}

/* ********************* user profil formation ******************* */
.profil-name{
    font-weight: 600 !important;
    /* color: red !important; */
    font-size: calc(1rem + 1vh);
}
.profil-body p{
    color: #000000;
    opacity: .6;
    font-weight: 500 !important;
    font-size: calc(0.4rem + .8vh);
}
.school-square{
    width: 50px;
    height: 50px;
    background-color: rgb(144, 138, 138);
}
.formation-card h3{
    font-size: calc(0.5rem + 1vh);
}
.formation-card .date-time{
    color: #000000;
    opacity: .6;
    font-weight: 500 !important;
}

.filepond--root .filepond--drop-label {
    min-height: 4.75em;
    cursor: pointer !important;
    transition: all 200ms;  
}
.filepond--root .filepond--drop-label * {
    cursor: pointer !important;  
}
.filepond--root .filepond--drop-label:hover {
    min-height: 4.75em;
    background-color: rgb(73, 72, 72, .2) !important;
    cursor: pointer !important;  
    border-radius: 8px !important;
}

/* ********************** pagination ********************* */

/* *********************** pagination* ****************** */
ul[aria-label="Pagination"]{
    display: flex;
    flex-wrap: wrap;
    /* overflow-x: auto; */
    gap: 2vw;
    /* column-gap: 20px; */
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 !important;
}
ul[aria-label="Pagination"] li{
    color: white;
}
ul[aria-label="Pagination"] a{
    display: inline-block;
    text-align: center;
    padding: 0.5rem 0.8rem;
    background-color: #009688;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 100%;
    min-width: 32px;
    min-height: 32px;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
li.selected a{
    background-color: #ffffff;
    box-shadow: 0px 0px 3px #009688;
    color: #009688 !important;
}
li.previous a{
    background-color: #003436;
    text-decoration: none;
}
li.next a{
    background-color: #003436;
    text-decoration: none;
}
li.next a, li.previous a{
    padding: 0.5rem;
}


  /* ********************* react-bootstrap ********************* */
  .react-bootstrap-table{
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
  }
  table{
    /* border: none !important; */
  }
  table > thead{
    /* border-collapse: separate; */
    /* border-top: none; */
    border-bottom: 2px solid rgba(0, 0, 0, .2);
}
th{
    border: none;
    padding: 15px !important;
    font-size: 14px;
    font-weight: 700 !important;
    vertical-align: middle;
    /* text-align: center; */
    color: var(--secondary);
    font-size: 12px !important;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* border: none; */
}
td{
    /* text-align: center; */
    font-size: 12px !important;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px !important;
    font-weight: 400;
    border: none !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

  .react-bootstrap-table-pagination div{
    display: flex;
    justify-content: flex-start;
}
.react-bootstrap-table-pagination-list{
    display: flex;
    justify-content: flex-end !important;
}
li.page-item .page-link{
    color: #069a77;
    padding: 0.5rem 1rem !important;
}
li.page-item.active .page-link{
    background-color: #069a77;
    padding: 0.5rem 1rem;
    border-color: #aaa;
    color: white;
}
button#pageDropDown{
    background-color: #069a77;
    padding: 0.5rem 1rem !important;
}

.dropdown-menu{
    padding: 0.5rem;
}

.react-bootstrap-table-pagination{
    margin-top: 1rem !important;
}

.dropdown-item a{
    padding: 0.5rem !important;
}
.react-bootstrap-table-pagination .dropdown-menu li a:hover{
    background-color: #96e3e8;
}

.react-bootstrap-table-pagination .dropdown-menu li a{
    color: #00BEC9;
    text-decoration: none;
    font-weight: bold;
    display: block;
    padding: 0.5rem;
    width: 100%;
}

::-webkit-scrollbar {
    width: 8px;
    height: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #356f69;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #3e706b;
    border-radius: 10px;
  }

.react-bootstrap-table{
    overflow-x: scroll !important;
}

table  thead th{
    font-weight: 500;
    padding: 0.5rem !important;
    /* text-align: center; */
}
tbody > tr{
    border: 1px solid gray;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 1.5rem !important;
    background-color: white;
}
td p{
    margin: 0;
    padding: 0.5rem;
    word-wrap: normal;
}
/* td:nth-child(2n){
    border-left: 1px solid rgb(183, 180, 180) !important;
    border-right: 1px solid rgb(183, 180, 180) !important;
}
td:last-child{
    border-right: none !important;
} */

/* ***************** filter btn ******************* */
.filter-btn{
    max-width: 500px;
    padding-left: 50px;
}
.search-icon{
    position: absolute;
    left: 28px;
    top: 50%;
    transform: translate(-50%, -50%);
}
/* .btn-add{
    font-weight: 500 !important;
    background-color: #00BEC9;
    color: white;
    border: none;
    border-radius: 0px !important;
    box-shadow: 0px 1px 1px rgba(10, 12, 15, 0.25);
} */

/* ******************** Pagination ****************** */
.react-bootstrap-table-pagination{
    background-color: #EFEDED;
    padding: 0.3rem 0.5rem;
}
.react-bootstrap-table-page-btns-ul{
    justify-content: flex-end;
    margin: 0;
}
#pageDropDown{
    background-color: #236363 !important;
    color: white !important;
    border: none;
    padding: 0.5rem 1rem !important;
}
.dropdown-menu{
    width: 20px !important;
}
.dropdown-item a{
    text-decoration: none;
    color: #181919;
}
.page-link{
    color: #181919 !important;
    padding: 0.5rem 1rem !important;
}
.active.page-item .page-link{
    background-color: #236363 !important;
    text-decoration: none ;
    border: none;
    color: white !important;
    /* font-size: 2rem !important; */
}

/************************ ACCORDION ***************************/
.accordion-button.collapsed{
    color: rgba(10, 15, 25, 0.7);
}
.accordion-button:not(.collapsed){
    color: rgba(10, 15, 25, 0.9);
    /* background-color: #587572;
    outline: none !important; */
}

/* .accordion-button.collapsed::after{
    color: red !important;
} */

/* ******************** offres ***************** */
.voir-plus{
    transition: all 300ms;
}
.voir-plus:hover{
    background-color: #248077 !important;
    color: white;
}

/* ************************** modal ****************** */
.modal-dialog{
    max-width: 600px !important;
}
#contained-modal{
    max-width: 1200px !important;
}
#contained-modal-parent{
    /* display: flex; */
    /* width: 100%; */
}
.modal-content{
    /* display: flex !important; */
    /* flex-direction: row; */
    background-color: white !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image{
    min-height: 500px !important;
    max-height: 700px;
    /* width: 100px; */
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: color-burn;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
    height: 50px !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    /* vertical-align: middle;
    width: 100%;
    line-height: 0;
    height: 100px;
    width: 100px;
    object-fit: cover; */
    width: 100px;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: color-burn;
}

/* ************************ truncate *************************** */
.truncate{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    height:56px;

}

/* ************* wysiwyg*********** */
.ql-container{
    min-height: 300px;
}

/* ************************* loading *************************** */
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #565353;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 5px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 5px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 20px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 20px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  /* ******************* navigateback ******************** */
  .navigate--back:hover{
    opacity: .6;
  }
  .hover-1:hover{
    opacity: 0.55;
  }


@media screen and (max-width: 766px) {
    ul[aria-label="Pagination"]{
        gap: 1rem;
        justify-content: center;
        padding: 0;
    }
}
@media screen and (max-width: 575px) {
    .offres-cards-container{
        display: flex;
        gap: 0.5rem;
    }
}
@media screen and (max-width: 499px) {
    ul[aria-label="Pagination"]{
        gap: 1rem;
        justify-content: center;
    }
    ul[aria-label="Pagination"] li{
        min-width: auto;
    }
    ul[aria-label="Pagination"] a{
        padding: 0.3rem 0.6rem;
        font-size: 0.5rem;
        font-weight: 400;
    }
    li.next a, li.previous a{
        padding: 0.5rem;
    }
}

@media screen and (max-width: 425px) {
    .offres-cards-container{
        flex-direction: column;
        gap: 0.3rem;
    }
}

/* *{
    outline: 1px solid brown;
} */
