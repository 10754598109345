.register {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background: #f4f4f4;
  display: flex;
}

.register .registerContainer {
  padding: 40px;
  margin: 0 auto;
  width: 45%;
  max-width: 5200px;
  min-height: 200px;
  display: flex;
  border: none;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.btnInsc{
  text-align: center;
}


.login .loginContainer .btnContainer p span {
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}


