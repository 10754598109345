/* .entrepriseContainer{
    padding: 40px;
    margin: auto;
    width: 45%;
    max-width: 520px;
    min-height: 200px;
    display: flex;
    border: none;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}


.ch{
    width: 100%;
    height: 80px;
    background-color: #009688;
    padding: 1px;
    margin-bottom: 0;
    color: whitesmoke;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.taille-logo{
    width: 70%;
    height: 40px;
    margin-left: 1pc;
   
}
.cardFooter{
    width: 100%;
    height: 50px;
    background-color: #eff0f0;
    color: whitesmoke;
    padding: 5px;
}
.textDesc{
    padding: auto;
}

.description{
    font-size: 14px;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.logoEtpse{
    background-color: white;
    position: relative;
    margin-top: -3rem;
    width: 100px;
    height: 100px;
    object-fit: contain;
}
.nameEtps{
    
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-top: -2rem;
    text-align: center;
    position: relative;
}

.linkCard{
    color: rgb(12, 15, 17);
    cursor: pointer;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-left: 2rem;
    padding-top: 3rem;
} */

/* **************** sidebar ************** */
.sidebar-entreprise{
  text-decoration: none !important;
  border: none !important;
  color: black !important;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #344767 !important;
  margin-bottom: 10px;
  border-radius: 5px;
}
.sidebar-entreprise:hover{
  background-color: #f1eeee;
}
.sidebar-entreprise.active{
  color: white   !important;
  background-color: #111111;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgba(64, 64, 64, 0.4) 0px 7px 10px -5px;
}


.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    top: 5px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;

  }
  .stepper-item .step-counter-red {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    top: 5px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
  }
  .stepper-item .step-counter-orange {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    top: 5px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;

  }
  .stepper-item.active {
    font-weight: bold;
  }
  
  .stepper-item.completed .step-counter {
    background-color: #009688;
  }
  .stepper-item.completed .step-counter-orange {
    background-color: #FF9800;
  }
  .stepper-item.completed .step-counter-red {
    background-color: red;
  }
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 4px solid #009688;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
  










.title-liste-entreprise{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 50px;
    text-align: justify;
    color: #009688;
    margin-left: 5%;
}

.txt-card-icon-entreprise span{
    font-style: normal;
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 9px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.75);
}
span.dep-list-profil-entreprise{
    margin-left: 5%;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.75);
}

.desc{
    font-size: 12px;
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.position-voir-plus-offre{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 26px;
    color: #009688;
    text-decoration: none;
    outline: none;
}
.position-voir-plus-offre:hover{
    text-decoration: none;
    color: #009688;
}

.banner-top{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    width: 100%;
    background: #009688;
}

.one-card-img-entreprise{
    min-width: 300px;
    max-width: 350px;
    border-radius: 7%;
    position: relative;
    width: 100%;
    background-color: red;
    /* display: flex; */
    /* height: 93%; */
    cursor: pointer;
    box-shadow: 5.19557px 5.19557px 20.7823px 5.19557px rgba(0, 0, 0, 0.1);
}
.title-card-entreprise{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #009688;
}
.voir-nos-offres{
    text-align: right;
}
.none-underline-link{
    text-decoration: none;
    outline: none;
}
.bg-heart-ui{
    color: #FF9800;
}


.img-card-radius-home-ent{
    width: 60px;
    height: 60px;
}

.en-savoir-plus {
    text-align: right;
    padding-right: 2rem;
    padding-bottom: 1rem;

  }

  .position-en-s-plus {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #009688;
    text-decoration: none;
    outline: none;
    
  }
  .position-en-s-plus:hover {
    color: #009688;
    text-decoration: none;
    outline: none;
  }


  .info-content-syllabus-item{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(13, 12, 12, 0.8);
    display: flex;
    align-items: center;
}
/* ********************** entreprise ********************** */
.row-entreprise{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.row-entreprise .card{
  max-height: 450px;
}
.row-entreprise .card h5, .offresCard h5{
  font-size: calc(0.5rem + 1vh);
}
.row-entreprise .card p, .offresCard p{
  font-size: calc(0.5rem + 1.2vh);
}
.row-entreprise .card span, .offresCard span{
  font-size: calc(0.5rem + 0.6vh);
}
.row-entreprise  .card-img-top{
  height: 200px;
  object-fit: cover;
}
.img-circle{
  /* width: 50px;
  height: 50px;
  box-shadow: 0px 2px 5px rgba(13, 12, 12, 0.5);
  border-radius: 50%; */
  height: 75px;
  width: 75px;
  background-position: 50%;
  background-size: 65%;
  background-color: #fff;
  background-repeat: no-repeat;
  border: 5px solid #3ac1b3b7;
  position: absolute;
  border-radius: 10000px;
    top: -40px;
}
.img-circle img{
  width: 100%;
  /* object-fit: contain; */
  border-radius: 10000px;
}
.card-body-text{
  margin-top: 2rem;
}

@media screen and (max-width: 1200px) {
  .row-entreprise{
    grid-template-columns: repeat(2, 1fr);
  }
  .img-circle{
    height: 75px;
    width: 75px;
      top: -40px; 
  }
}
@media screen and (max-width: 640px) {
  .row-entreprise{
    /* grid-template-columns: repeat(2, 1fr); */
    grid-template-columns: 1fr
  }
  .row-entreprise .card{
    max-height: max-content;
  }
  .row-entreprise  .card-img-top{
    height: 120px;
  }
  .img-circle{
    /* width: 50px;
    height: 50px;
    box-shadow: 0px 2px 5px rgba(13, 12, 12, 0.5);
    border-radius: 50%; */
    height: 70px;
    width: 70px;
      /* transform: translate(0px, -50px); */
      top: -40px;
  }
}

@media screen and (max-width: 570px) {
  .row-entreprise{
    grid-template-columns: 1fr
  }
}