/* .menu-nav {
    background-color: #44B;
    display: flex;
    justify-content: space-between;
  } */
  
  .menu-item {
    color: #FCC;
    padding: 3px;
  }
  
  .three-dots::after {
    cursor: pointer;
    color: #000;
    content: '\2807';
    font-size: 20px;
    padding: 0 5px;
    position: absolute;
    top: 0;
    right: 2px;
  }
  
  a {
    text-decoration: none;
    color: white;
  }
  
  a div {
    padding: 2px;
  }
  
  .dropdown {
    position: absolute;
    right: 25px;
    top: -120px;
    background-color: #fff;
    box-shadow: 0px 0px 3px 3px rgba(10, 15, 25, 0.1);
    outline: none;
    opacity: 0;
    z-index: -1;
    /* max-height: 0; */
    min-width: 90px;
    text-align: start;
    padding: 10px;
    border-radius: 10px;
    transition: opacity 1s, z-index 0.1s;
  }

  .dropdown-btn{
    background-color: #009688;
    border: none;
    color: white;
    transition: all 200ms ease;
    border-radius: 5px;
    font-size: 11px;
     font-weight: 700;
  }
  .dropdown-btn-red{
    background-color: rgb(229, 49, 49);
  }
  .dropdown-btn-yellow{
    background-color: darkorange;
  }
  .dropdown-btn:hover{
    opacity: 0.7;
  }
  
  .dropdown-container:focus {
    outline: none;
  }
  
  .show-dropdown {
    opacity: 1;
    z-index: 100;
    max-height: 100vh;
    transition: opacity 1s, z-index 0.1s;
  }