@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, sans-serif;
  line-height: 1.6;
  font-size: 18px;
  background-color: #f6f6f9 !important;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.form-control:focus,
select:focus {
  box-shadow: none !important;
}

.white-bg {
  background: #ffffff !important;
  border: 1px solid #ebe7e7;
  border-radius: 5px;
}

.offreVide {
  color: #009688;
  font-size: 18px;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
a:hover {
  text-decoration: none;
  color: initial;
}

.btn {
  font-weight: 500;
  font-size: 14px;
}

.h-theme {
  color: #009688;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}
.btn-red {
  background: #f44336;
  color: white;
}

.btn-red:hover {
  background: #fff;
  color: #f44336;
  border: 1px solid #f44336;
}

.btn-add {
  background: #009688;
  color: white;
}

.btn-add:hover {
  background: #009688;
  color: white;
}

.btn-import {
  background: #ff9800;
  color: white;
}

.btn-import:hover {
  background: #ff9800;
  color: white;
}

.btn-apply {
  background: #009688;
  color: #fff;
}

.btn-apply:hover {
  background: #fff;
  color: #009688;
  border: 1px solid #009688;
}

.btn-edit {
  background: #f6f6f9;
}

.btn-edit:hover {
  background: lightgrey;
}

.btn-pdf {
  background: #f6f6f9;
  border: 1px solid black;
}

.btn-pdf:hover {
  background: lightgrey;
  border: 1px solid black;
}

.bakeli-color {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #ff9800;
}
.carriere-color {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  /* text-align: center; */
  text-transform: uppercase;
  color: #009688;
  padding-left: 3%;
}

.link {
  text-decoration: underline;
  color: dodgerblue;
  cursor: pointer;
  font-weight: 500;
}

.niveauPassword {
  margin-top: 1rem;
  font-weight: bold;
}

/* Cards styles */

.offresCard img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.loader_heigth {
  height: 6rem;
}

.offresCard span {
  font-size: 16px;
  color: #808080;
  font-weight: 400;
}
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

/* Filter component styles */
.search_filter_container {
  background: #fff;
  margin: 20px 0;
  padding: 25px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 5%);
  box-sizing: border-box;
}

.search_input {
  border: none;
  /* padding: 5px; */
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 600;
  height: 50px;
  width: 100%;
}

.search_input::placeholder {
  color: #000000;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 400;
}

.search_filter_icon {
  font-size: 24px;
  color: #000000;
}

.reset_filter_icon {
  cursor: pointer;
  color: red;
}

.search_filter_select {
  border: none;
  height: 100%;
  color: #000000;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 600;
}

/* Avatar Initials */
#initials {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 50px; */
  width: 40px;
  height: 40px;
  background-color: #009688;
  border-radius: 50%;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

/* EXPERIENCE TYPE STYLES */
.cdi {
  background: #009688;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.stage {
  background: #ff9800;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.cdd {
  background: lightgray;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.myCustomDropdown {
  position: absolute;
  z-index: 9999;
}

/* For Spinners */
.spinner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #000000 94%, #0000) top/3.8px 3.8px
      no-repeat,
    conic-gradient(#0000 30%, #000000);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 3.8px), #000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 3.8px),
    #000 0
  );
  animation: spinner-c7wet2 0.6s infinite linear;
}

p{
  font-weight: 300 !important;
  font-size: 0.8rem;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}
